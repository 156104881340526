import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import RefillmentProteinsImage from './../images/refillment/proteins.png'
import FlavourMobileImage from './../images/refillment/flavors-mobile.png'
import FlavourMobileImageLarge from './../images/refillment/flavors-mobile@2x.png'

import { TweenLite } from 'gsap/all'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'
import { ScrollTrigger } from 'gsap/all'
import Carousel from '../components/ux/carousel/carousel'
import { connect } from 'react-redux'
import { Image, ImageRef } from '../components/ux/image'

import CloudShopMacbookImage from './../images/cloud/Shop_MacBook.png'
import CloudShopMacbookImageLarge from './../images/cloud/Shop_MacBook@2x.png'
import CloudShopIPhoneImage from './../images/cloud/Shop_iPhone.png'
import CloudShopIPhoneImageLarge from './../images/cloud/Shop_iPhone@2x.png'

// Bilder Mobile
import CloudShopImageMobile from './../images/cloud/cloud-shop.png'
import CloudShopImageMobileLarge from './../images/cloud/cloud-shop@2x.png'


function Refillment({ translations, lang }) {
    const lightHolderRef = useRef();
    const swingerRef = useRef();

    const [rendered, setRendered] = useState(false)

    createAnimationType();

    // Scrollrefs
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRef3 = useRef();
    const scrollRefs = [
        scrollRef1,
        scrollRef2,
        scrollRef3,
    ];

    // Animation Refs
    /* Szene 1 */
    const h1Ref = useRef();
    const textRef = useRef();
    /* Szene 2 */
    const imageRef2 = useRef();
    const h1Ref2 = useRef();
    const textRef2 = useRef();
    /* Szene 3 */
    const imageRef3 = useRef();
    const h1Ref3 = useRef();
    const textRef3 = useRef();

    const carouselRef = useRef();

    // Aktuelle Position, Status etc.
    let currentScrollPos = useRef(1);
    let previousScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);
    let clickable = useRef(false);


    // Animationen für jede Section
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        clickable.current = false;
        switch (step) {
            case 2:
                // Alte Szene ausblenden
                TweenLite.to(h1Ref.current, { x: -50, y: -150, autoAlpha: 0 });
                TweenLite.to(textRef.current, { x: -50, y: -100, autoAlpha: 0 });
                TweenLite.to(carouselRef.current, { x: -50, y: -50, autoAlpha: 0 });

                if (previousScrollPos.current === 3) {
                    TweenLite.to(h1Ref3.current, { x: 50, y: 150, autoAlpha: 0 });
                    TweenLite.to(textRef3.current, { x: 50, y: 100, autoAlpha: 0 });
                    TweenLite.to(imageRef3.current, {
                        scale: .75, y: 50, autoAlpha: 0, onComplete: () => {
                            resetElements([h1Ref3.current, textRef3.current, imageRef3.current])
                        }
                    });
                }


                // Neue Szene einblenden
                TweenLite.from(h1Ref2.current, { x: 50, y: 70, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(textRef2.current, { x: 50, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(imageRef2.current, { scale: .75, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.to(swingerRef.current, {
                    xPercent: -100, rotation: 90, duration: 1.5, onComplete: () => {
                        animationActive.current = false;
                        resetElements([h1Ref.current, textRef.current, carouselRef.current]);
                        clickable.current = true;
                    }
                });
                break;
            case 3:
                // Alte Szene ausblenden
                TweenLite.to(h1Ref2.current, { x: -50, y: -150, autoAlpha: 0 });
                TweenLite.to(textRef2.current, { x: -50, y: -100, autoAlpha: 0 });
                TweenLite.to(imageRef2.current, { scale: .75, y: -50, autoAlpha: 0 });

                // Neue Szene einblenden
                TweenLite.from(h1Ref3.current, { x: 50, y: 70, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(textRef3.current, { x: 50, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(imageRef3.current, { scale: .75, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.to(swingerRef.current, {
                    xPercent: -100, rotation: 90, duration: 1.5, onComplete: () => {
                        animationActive.current = false;
                        clickable.current = true;
                        resetElements([h1Ref2.current, textRef2.current, imageRef2.current]);
                    }
                });
                break;
            default: // Standard Introanimation
                if (entered.current === true) {
                    TweenLite.to(h1Ref2.current, { x: 50, y: 150, autoAlpha: 0 });
                    TweenLite.to(textRef2.current, { x: 50, y: 100, autoAlpha: 0 });
                    TweenLite.to(imageRef2.current, { scale: .75, y: 50, autoAlpha: 0 });
                }
                TweenLite.from(h1Ref.current, { x: -150, y: -50, autoAlpha: 0 });
                TweenLite.from(textRef.current, {
                    x: -150, y: 50, autoAlpha: 0, onComplete: () => {
                        animationActive.current = false;
                        if (entered.current === false) {
                            clickable.current = true;
                        }
                        entered.current = true;
                    }
                });
                TweenLite.from(carouselRef.current, { x: 50, y: -50, autoAlpha: 0 });
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, {
                        ease: animationType, xPercent: 0, rotation: 0, duration: 1.5, onComplete: () => {
                            clickable.current = true;
                            resetElements([h1Ref2.current, textRef2.current, imageRef2.current]);
                        }
                    })
                } else { // Startanimation
                    TweenLite.from(swingerRef.current, { ease: animationType, x: 300, autoAlpha: 0, duration: 1 })
                }
                break;

        }
        previousScrollPos.current = step;
    }, []);

    // Scrollt zur nächsten Section
    const scrollToRef = (ref) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 3) { // Bis footer
            offset += 180;
        }
        TweenLite.to(window, { duration: 1.5, scrollTo: offset });

    };

    // Initialisierung
    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;
            if (rendered !== true) {
                setRendered(true)
                setTimeout(() => {
                    scrollEffect(1);
                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);

                }, 500);
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 2000)
            }
            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])

    const clickHandler = () => {
        if (clickable.current === false || !doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    return (
        <div>
            <Layout>
                <SEO title={translations.refillment_title} description={translations.refillment_meta_description} />
                <div className="refillment subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>
                    {/* Szene 1 */}
                    <section ref={scrollRef1}>
                        <div className="swinger animationElement" ref={swingerRef}></div>
                        <div className="inner-wrapper flex">
                            <div>
                                <div ref={h1Ref} className="animationElement">
                                    <h1>
                                        {
                                            nl2br(translations.refillment_1_yellow_headline)
                                        }
                                        <span className="yellow"><br />
                                            {
                                                nl2br(translations.refillment_1_white_headline)
                                            }
                                        </span>
                                    </h1>
                                    <p className="t-upper mt mb bolder">
                                        {
                                            nl2br(translations.refillment_1_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={FlavourMobileImage} img2={FlavourMobileImageLarge} alt="Flavors" styles="mobile" />
                                <div ref={textRef} className="animationElement">
                                    <p className="light">
                                        {
                                            nl2br(translations.refillment_1_description)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div ref={carouselRef} className="animationElement"><Carousel /></div>
                        </div>
                    </section>
                    {/* Szene 2 */}
                    <section ref={scrollRef2}>
                        <div className="inner-wrapper flex mt-3">
                            <div ref={imageRef2}>
                                <img src={RefillmentProteinsImage} alt="Bodyshake Protein" className="mobile-exclude" />
                            </div>
                            <div className="flex center left column">
                                <div ref={h1Ref2} className="animationElement">
                                    <div className="bs-headline">
                                        {
                                            nl2br(translations.refillment_2_white_headline)
                                        }
                                        <br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.refillment_2_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="t-upper bolder mt mb">
                                        {
                                            nl2br(translations.refillment_2_subline)
                                        }
                                    </p>
                                    <Image img={RefillmentProteinsImage} img2={RefillmentProteinsImage} alt="Refillmentproducts" styles="mobile hovergrow" />
                                </div>
                                <div ref={textRef2} className="animationElement">
                                    <p className="light mb-2">
                                        {
                                            nl2br(translations.refillment_2_description)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Szene 3 */}
                    <section ref={scrollRef3} className="third">
                        <div className="inner-wrapper flex">
                            <div ref={imageRef3} className="animationElement image-holder">
                                <ImageRef img={CloudShopMacbookImage} img2={CloudShopMacbookImageLarge} alt="Cloud Shop iMac" className="hovergrow mobile-exclude" />
                                <ImageRef img={CloudShopIPhoneImage} img2={CloudShopIPhoneImageLarge} alt="Cloud Shop iPhone" className="cloudimage hovergrow down mobile-exclude" />
                            </div>
                            <div>
                                <div ref={h1Ref3} className="animationElement">
                                    <div className="bs-headline">
                                        {
                                            nl2br(translations.cloud_2_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.cloud_2_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="t-upper mt mb bolder">
                                        {
                                            nl2br(translations.cloud_2_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={CloudShopImageMobile} img2={CloudShopImageMobileLarge} alt="Cloud Machines" styles="mobile hovergrow mobile scale-80" />
                                <div ref={textRef3} className="animationElement">
                                    <p className="light ">
                                        {
                                            nl2br(translations.cloud_2_description)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(Refillment)