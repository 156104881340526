import { TweenLite } from 'gsap/all';
import { TweenMax } from 'gsap/gsap-core';
import React, { useCallback, useEffect, useRef } from 'react'

// Css
import './carousel.scss'
// Images
import flavour_1 from './../../../images/refillment/flavors/01.png'
import flavour_2 from './../../../images/refillment/flavors/02.png'
import flavour_3 from './../../../images/refillment/flavors/03.png'
import flavour_4 from './../../../images/refillment/flavors/04.png'
import flavour_5 from './../../../images/refillment/flavors/05.png'
import flavour_6 from './../../../images/refillment/flavors/06.png'
import flavour_7 from './../../../images/refillment/flavors/07.png'
import flavour_8 from './../../../images/refillment/flavors/08.png'
import flavour_9 from './../../../images/refillment/flavors/09.png'
import flavour_10 from './../../../images/refillment/flavors/10.png'
import flavour_11 from './../../../images/refillment/flavors/11.png'
import flavour_12 from './../../../images/refillment/flavors/12.png'
import flavour_13 from './../../../images/refillment/flavors/13.png'
import flavour_14 from './../../../images/refillment/flavors/14.png'
import flavour_15 from './../../../images/refillment/flavors/15.png'
import flavour_16 from './../../../images/refillment/flavors/16.png'
import flavour_17 from './../../../images/refillment/flavors/17.png'
import flavour_18 from './../../../images/refillment/flavors/18.png'
import flavour_19 from './../../../images/refillment/flavors/19.png'
import flavour_20 from './../../../images/refillment/flavors/20.png'
import flavour_21 from './../../../images/refillment/flavors/21.png'
import flavour_22 from './../../../images/refillment/flavors/22.png'
import flavour_23 from './../../../images/refillment/flavors/23.png'
import flavour_24 from './../../../images/refillment/flavors/24.png'
import flavour_25 from './../../../images/refillment/flavors/25.png'
import flavour_26 from './../../../images/refillment/flavors/26.png'
import flavour_27 from './../../../images/refillment/flavors/27.png'
import flavour_28 from './../../../images/refillment/flavors/28.png'
import flavour_29 from './../../../images/refillment/flavors/29.png'

// Images Double
import flavour_double_1 from './../../../images/refillment/flavors/01@2x.png'
import flavour_double_2 from './../../../images/refillment/flavors/02@2x.png'
import flavour_double_3 from './../../../images/refillment/flavors/03@2x.png'
import flavour_double_4 from './../../../images/refillment/flavors/04@2x.png'
import flavour_double_5 from './../../../images/refillment/flavors/05@2x.png'
import flavour_double_6 from './../../../images/refillment/flavors/06@2x.png'
import flavour_double_7 from './../../../images/refillment/flavors/07@2x.png'
import flavour_double_8 from './../../../images/refillment/flavors/08@2x.png'
import flavour_double_9 from './../../../images/refillment/flavors/09@2x.png'
import flavour_double_10 from './../../../images/refillment/flavors/10@2x.png'
import flavour_double_11 from './../../../images/refillment/flavors/11@2x.png'
import flavour_double_12 from './../../../images/refillment/flavors/12@2x.png'
import flavour_double_13 from './../../../images/refillment/flavors/13@2x.png'
import flavour_double_14 from './../../../images/refillment/flavors/14@2x.png'
import flavour_double_15 from './../../../images/refillment/flavors/15@2x.png'
import flavour_double_16 from './../../../images/refillment/flavors/16@2x.png'
import flavour_double_17 from './../../../images/refillment/flavors/17@2x.png'
import flavour_double_18 from './../../../images/refillment/flavors/18@2x.png'
import flavour_double_19 from './../../../images/refillment/flavors/19@2x.png'
import flavour_double_20 from './../../../images/refillment/flavors/20@2x.png'
import flavour_double_21 from './../../../images/refillment/flavors/21@2x.png'
import flavour_double_22 from './../../../images/refillment/flavors/22@2x.png'
import flavour_double_23 from './../../../images/refillment/flavors/23@2x.png'
import flavour_double_24 from './../../../images/refillment/flavors/24@2x.png'
import flavour_double_25 from './../../../images/refillment/flavors/25@2x.png'
import flavour_double_26 from './../../../images/refillment/flavors/26@2x.png'
import flavour_double_27 from './../../../images/refillment/flavors/27@2x.png'
import flavour_double_28 from './../../../images/refillment/flavors/28@2x.png'
import flavour_double_29 from './../../../images/refillment/flavors/29@2x.png'
import { createAnimationType, doAnimateCarousel } from '../../../utils/functions';
import { Image } from '../image'

function Carousel() {
    const stageRef = useRef();

    createAnimationType();

    const images = [
        flavour_1,
        flavour_2,
        flavour_3,
        flavour_4,
        flavour_5,
        flavour_6,
        flavour_7,
        flavour_8,
        flavour_9,
        flavour_10,
        flavour_11,
        flavour_12,
        flavour_13,
        flavour_14,
        flavour_15,
        flavour_16,
        flavour_17,
        flavour_18,
        flavour_19,
        flavour_20,
        flavour_21,
        flavour_22,
        flavour_23,
        flavour_24,
        flavour_25,
        flavour_26,
        flavour_27,
        flavour_28,
        flavour_29,
    ];

    const images_double = [
        flavour_double_1,
        flavour_double_2,
        flavour_double_3,
        flavour_double_4,
        flavour_double_5,
        flavour_double_6,
        flavour_double_7,
        flavour_double_8,
        flavour_double_9,
        flavour_double_10,
        flavour_double_11,
        flavour_double_12,
        flavour_double_13,
        flavour_double_14,
        flavour_double_15,
        flavour_double_16,
        flavour_double_17,
        flavour_double_18,
        flavour_double_19,
        flavour_double_20,
        flavour_double_21,
        flavour_double_22,
        flavour_double_23,
        flavour_double_24,
        flavour_double_25,
        flavour_double_26,
        flavour_double_27,
        flavour_double_28,
        flavour_double_29,
    ];

    const indexes = [
        { zIndex: 5 },
        { zIndex: 6 },
        { zIndex: 7 },
        { zIndex: 8 },
        { zIndex: 9 },
        { zIndex: 10 },
        { zIndex: 15 },
        { zIndex: 20 },
        { zIndex: 15 },
        { zIndex: 10 },
        { zIndex: 9 },
        { zIndex: 8 },
        { zIndex: 7 },
        { zIndex: 6 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
        { zIndex: 2 },
    ]

    const elProps = [
        { x: 30, y: -150, scale: .15, webkitFilter: 'brightness(0.4)', opacity: 1 },
        { x: -20, y: -140, scale: .19, webkitFilter: 'brightness(0.5)', opacity: 1 },
        { x: -60, y: -120, scale: .22, webkitFilter: 'brightness(0.5)', opacity: 1 },
        { x: -100, y: -100, scale: .25, webkitFilter: 'brightness(0.5)', opacity: 1 },
        { x: -140, y: -80, scale: .25, webkitFilter: 'brightness(0.6)', opacity: 1 },
        { x: -120, y: -35, scale: .35, webkitFilter: 'brightness(0.7)', opacity: 1 },
        { x: -50, y: 20, scale: .45, webkitFilter: 'brightness(1)', opacity: 1 },
        { x: 140, y: 50, scale: 1, webkitFilter: 'brightness(1)', opacity: 1 },
        { x: 330, y: 20, scale: .45, webkitFilter: 'brightness(1)', opacity: 1 },
        { x: 405, y: -35, scale: .35, webkitFilter: 'brightness(0.7)', opacity: 1 },
        { x: 420, y: -80, scale: .25, webkitFilter: 'brightness(0.6)', opacity: 1 },
        { x: 400, y: -100, scale: .25, webkitFilter: 'brightness(0.6)', opacity: 1 },
        { x: 360, y: -120, scale: .22, webkitFilter: 'brightness(0.6)', opacity: 1 },
        { x: 300, y: -140, scale: .19, webkitFilter: 'brightness(0.5)', opacity: 1 },
        { x: 250, y: -150, scale: .15, webkitFilter: 'brightness(0.4)', opacity: 1 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
        { x: 200, y: -150, scale: .15, opacity: 0 },
    ];


    const refs = [];

    images.forEach(img => {
        refs.push(React.createRef());
    })

    const imageElements = images.map((image, index) => (
        <div className="box" key={`box_${index}`} ref={refs[index]}>
            <Image img={image} img2={images_double[index]} key={index} width="285" height="285" alt="flavour" />
        </div>
    ));

    const initCarousel = useCallback(() => {
        refs.forEach((ref, index) => {
            TweenLite.set(ref.current, { ...elProps[index], ...indexes[index] });
        })
    }, [refs, elProps, indexes]);

    useEffect(() => {
        if (doAnimateCarousel()) {
            initCarousel();
        }
    }, [initCarousel]);

    // Elemente bei Klick rotieren indem die Positionen der Elemente getauscht werden
    // und die Eigenschaften der Elemente neu gesetzt werden
    const rotateCarousel = (direction) => {
        if (direction) {
            refs.push(refs.shift());
        } else {
            refs.unshift(refs.pop());
        }
        refs.forEach((ref, index) => {
            TweenMax.set(ref.current, indexes[index]);
        });

        elProps.forEach((prp, index) => {
            TweenMax.to(refs[index].current, 1, prp);
        })
    }

    return (
        <div className="carousel">
            <div className="stage break4" ref={stageRef}>
                {
                    imageElements.map(item => item)
                }
            </div>
            <div className="text-center rotation-controls">
                <button aria-label="Rotate left" className="rotateControl left" onClick={() => rotateCarousel(false)}><span className="icon-scroll-down"></span> </button>
                <button aria-label="Rotate right" className="rotateControl right" onClick={() => rotateCarousel(true)}><span className="icon-scroll-down"></span></button>
            </div>
        </div>
    )
}

export default Carousel
